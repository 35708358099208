var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fragment',[_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Quản lý tài khoản")])]),_c('li',{staticClass:"menu-item menu-item-submenu",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon fas fa-user-shield"}),_c('span',{staticClass:"menu-text"},[_vm._v("Tài khoản")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":{ name : 'account'}},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Danh sách tài khoản")])])])]}}])}),_c('router-link',{attrs:{"to":{ name : 'account-type-index'}},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Loại tài khoản")])])])]}}])}),_c('router-link',{attrs:{"to":{ name : 'permission-list'}},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Quyền")])])])]}}])}),_c('router-link',{attrs:{"to":{ name : 'role-list'}},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Nhóm quyền")])])])]}}])})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }